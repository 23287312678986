import gql from "graphql-tag";
import { claimFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $userId: ID!
      $policyId: ID!
      $dependentId: ID
      $meta: JSONObject!
      $status: ClaimStatusEnum
      $type: String
    ) {
      upsertClaim(
        input: {
          id: $id
          userId: $userId
          policyId: $policyId
          dependentId: $dependentId
          meta: $meta
          type: $type
          status: $status
        }
      ) {
        claim {
          ...Claim
        }
      }
    }
    ${claimFragment}
  `,
}
