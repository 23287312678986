import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import { groupSegmentsByType } from "@/utils";
import { orgPropertyFragment } from "../fragments";
import orgPropertiesSchema from "../schemas/orgProperties";
import orgPropertiesMutations from "../mutations/orgProperties";

export default {
  gqlType: "OrgProperty",
  fields: ["name", "orgName", "updatedAt", "property"],
  listQuery: getListQuery("OrgProperty"),
  singleQuery: getSingleQuery("OrgProperty"),
  singleQueryByName: gql`
    query GlobalOrgPropertyByName($name: String!) {
      globalOrgPropertyByName(name: $name) {
        ...OrgProperty
      }
    }
    ${orgPropertyFragment}
  `,
  ...orgPropertiesSchema,
  ...orgPropertiesMutations,
  validate: (n) => {
    // returns an error message if error exists, else returns false
    switch (n.name) {
      case "RELATIONSHIP_MANAGER_MAPPING": {
        if (!n?.node) return `Please select an option`;
        break;
      }
      case "ORG_SEGMENT": {
        if (!n?.nodes) return `Please select atleast one org segment configuration`;
        for (const segmentNode of n?.nodes) {
          if (Object.keys(segmentNode).length === 0) return `Org Segment field cannot be empty`;
        }
        const segmentsByType = groupSegmentsByType(n?.nodes);
        for (const type in segmentsByType) {
          if (segmentsByType[type].length > 1 && type === "FITNESS")
            return `Org cannot belong to multiple segments of the same type: ${type}`;
        }
        break;
      }
      default:
        return false;
    }
    return false;
  },
  transform: (n, write = false) => {
    if (write) {
      switch (n.name) {
        case "ORG_SEGMENT": {
          n.nodeIds = n?.nodes?.map((item) => item.node.id);
          break;
        }
        case "RELATIONSHIP_MANAGER_MAPPING": {
          n.nodeId = n.node?.id;
          break;
        }
      }
    }
    let property = "Check Metadata ->";
    if (n.node) property = n.node.name;
    if (n.nodes) property = n?.nodes?.map((item) => item.node.name);
    const payload = {
      ...n,
      property,
      orgId: n.org?.id,
      orgName: n.org?.name,
    };
    if (write) {
      delete payload.org;
    }
    return payload;
  },
};
