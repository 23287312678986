<template lang="pug">
.col-12.offset-md-1.col-md-10.px-0
  .features-container.row.position-relative.w-100.mt-4.mt-md-5.py-5.text-center.text-md-left.mx-0
    .features-left.col-12.col-md-4.py-4.d-flex.flex-column.justify-content-between.px-0
      .features-heading.mb-5
        .span.features-title.font-hergon.font-weight-semibold.pb-4.pb-md-0 {{ featuresData.title }}
        .font-lg.font-weight-medium.font-inter.text-gray-800.pr-0.pr-md-5.my-3(v-html="featuresData.description") 
    .features-list.col-12.col-md-4.py-4.px-3.float-right
      .pb-4.mb-3.d-flex.flex-column(v-for="feature of featuresFirstHalf")
        .feature-logo.px-0.px-md-4.pb-3
          b-img(:src="feature.icon ? feature.icon.url : ''")
        .feature-description.pl-0.pl-md-4
          h4.hb4 {{ feature.heading }}
          .font-md.font-inter.subheading.text-gray-800 {{ feature.subheading }}
        hr
    .features-list.col-12.col-md-4.py-4.px-3.float-right
      div.pb-4.mb-3.d-flex.flex-column(v-for="feature of featuresSecondHalf")
        .feature-logo.px-4.pb-3
          b-img(:src="feature.icon ? feature.icon.url : ''")
        .feature-description.pl-4
          h4.hb4 {{ feature.heading }}
          .font-md.font-inter.subheading.text-gray-800 {{ feature.subheading }}
        hr
</template>

<script>
export default {
  components: {},
  props: {
    featuresData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      featuresFirstHalf: [],
      featuresSecondHalf: [],
    };
  },
  mounted() {
    if(this.featuresData) {
      const half = Math.ceil(this.featuresData.feature.length / 2);
      this.featuresFirstHalf = this.featuresData.feature.slice(0, half);
      this.featuresSecondHalf = this.featuresData.feature.slice(half, this.featuresData.feature.length);
    }
  },
};
</script>
<style lang="scss">
.features-container {
  p {
    font-size: 1rem;
  }
}
</style>
<style lang="scss">
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.feature-container {
  min-height: 11.25rem;
}
.features-title {
  font-size: 1.75rem;
  @include media-breakpoint-down(sm) {
    font-size: 2.375rem;
  }
}
</style>