import gql from "graphql-tag";
import { challengeTemplateFragment } from "./challenges";
import { dependentFragment } from "./dependent";
import { strapiContentFragment } from "./strapi";
import { userBenefitFragmentWithoutContent } from "./userBenefit";
import { userChangeFragment } from "./userChange";


export const userFragment = gql`
  fragment User on User {
    id
    email
    name
    displayName
    gender
    dob
    meta
    status
    roles
    photoFile {
      id
      name
      url
      meta
    }
    org {
      id
      name
      status
      featureFlags
    }
    dependents {
      ...Dependent
    }
    benefits {
      ...UserBenefitEdgeWithoutContent
    }
    userChanges {
      ...UserChange
    }
  }
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${userChangeFragment}
`;

export const userWithChallengesFragment = gql`
  fragment UserWithChallenges on User {
    id
    email
    name
    displayName
    gender
    dob
    meta
    status
    roles
    photoFile {
      id
      name
      url
      meta
    }
    org {
      id
      name
      featureFlags
      orgChallenges {
        id
        challengeTemplate {
          ...ChallengeTemplate
          challengeContent {
            ...StrapiContent
          }
        }
        org {
          id
        }
        user {
          id
          displayName
          photoFile {
            id
            meta
          }
        }
        parentChallenge {
          id
        }
        goalValue
        currentTotal
        startDate
        startTime
        expiryDate
        expiryTime
        challengeStatus
        points
        userContributions {
          id
          currentTotal
          user {
            id
            name
            photoFile {
              id
              url
            }
          }
        }
        meta
        updatedAt
      }
      weeksUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
      monthsUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
      allTimeUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
    }
    userChallenges {
      id
      challengeTemplate {
        ...ChallengeTemplate
        challengeContent {
          ...StrapiContent
        }
      }
      user {
        id
        name
      }
      goalValue
      currentTotal
      startDate
      startTime
      expiryDate
      expiryTime
      challengeStatus
      points
      meta
      updatedAt
    }
    enrollableChallenges {
      id
      name
      duration
      baseGoalValue
      challengeContent {
        ...StrapiContent
      }
      activityTypes
      points
    }
  }
  ${challengeTemplateFragment}
  ${strapiContentFragment}
`;

export const userByEmployeeIdOrEmailFragment = gql`
  fragment UserByEmployeeIdOrEmail on User {
    email
    id
    gender
    name
    meta
    dob
  }
`;
