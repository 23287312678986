<template lang="pug">
.responsive-carousel.col-12.px-0
  b-carousel#testimonialCarouselMobile.col-12.p-0.mt-2.mt-md-5.d-md-none(v-model="slideMobile", :interval="3000")
    .carousel-item(v-for="card, idx of carouselData")
      .testimonial-card.rounded-2.py-4.mr-md-3.mb-3(:class="[`${cardClasses[idx % 3].shadow || ''}`, `${cardClasses[idx % 3].background}`]")
        .d-flex.align-items-center.justify-content-start.flex-column
          b-img(:src="require('@/assets/images/quote-icon.svg')")
          .w-100.d-flex.justify-content-center.pt-4
            n-avatar(
              :size="5",
              :name="card.employeeName",
              :image="card.employeeImage.url ? card.employeeImage.url : null",
            )
          h4.hb4.pt-3.m-0(:class="`${cardClasses[idx % 3].name}`") {{card.employeeName}}
          .font-inter.font-weight-medium.font-md(:class="`${cardClasses[idx % 3].title}`") {{card.employeeTitle}}
          .font-inter.font-md.font-italic.py-3.px-5.text-center(:class="`${cardClasses[idx % 3].testimonial}`") {{card.testimonial}}
  .indicators-wrapper.d-flex.justify-content-center.align-items-center.d-md-none.w-100
    .custom-indicator(v-for="i in carouselData.length" :class="`${i === slideMobile + 1 ? 'bg-gray-900' : 'bg-gray-400'}`")
  .arrow-controls-wrapper.col-6.ml-auto.d-none.d-md-flex.justify-content-md-end.align-items-md-end.pr-5
    .control.shadow-xs.d-flex.align-items-center.justify-content-center.mx-3.cursor-pointer(v-if="slide > 0", @click="slideLeft")
      i.icon-chevron-left.text-gray-700
    .control.shadow-xs.d-flex.align-items-center.justify-content-center.mx-3.cursor-pointer(v-if="carouselData && carouselData.length > 1", @click="slideRight")
      i.icon-chevron-right.text-gray-700
  b-carousel#testimonialCarousel.testimonial-carousel-container.col-12.p-0.mt-2.mt-md-5.d-none.d-md-block(v-model="slide", :interval="3000")
    .carousel-item.d-flex
      .testimonial-card.col-md-4.rounded-2.py-4.mr-md-3.mb-3(
        v-for="card of cardList", 
        :class="[`${cardClasses[getIdxByName(card.employeeName) % 3].shadow || ''}`, `${cardClasses[getIdxByName(card.employeeName) % 3].background}`]"
      )
        .d-flex.align-items-center.justify-content-start.flex-column
          b-img(:src="require('@/assets/images/quote-icon.svg')")
          .w-100.d-flex.justify-content-center.pt-4
            n-avatar(
              :size="5",
              :name="card.employeeName",
              :image="card.employeeImage.url ? card.employeeImage.url : null",
            )
          h4.hb4.pt-3.m-0(:class="`${cardClasses[getIdxByName(card.employeeName) % 3].name}`") {{card.employeeName}}
          .font-inter.font-weight-medium.font-md(:class="`${cardClasses[getIdxByName(card.employeeName) % 3].title}`") {{card.employeeTitle}}
          .font-inter.font-md.font-italic.py-3.px-5.text-center(:class="`${cardClasses[getIdxByName(card.employeeName) % 3].testimonial}`") {{card.testimonial}}
</template>

<script>
import NAvatar from "@/components/Avatar.vue";

export default {
  components: {
    NAvatar,
  },
  props: {
    carouselData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      cardList: [],
      slide: 0,
      slideMobile: 0,
      cardClasses: [
        {
          background: 'bg-teal-800',
          name: 'text-white',
          title: 'text-teal-200',
          testimonial: 'text-white',
        },
        {
          background: 'bg-white',
          name: 'text-gray-900',
          title: 'text-gray-700',
          testimonial: 'text-gray-800',
          shadow: 'shadow-sm',
        },
        {
          background: 'bg-tiber',
          name: 'text-white',
          title: 'text-malibu-300',
          testimonial: 'text-gray-300',
        },
      ],
    };
  },
  mounted() {
    // Creating cards list of max 3 testimonials to show in larger view
    if (this.carouselData) {
      this.cardList = this.carouselData.slice(0, Math.min(3, this.carouselData.length));
    }
  },
  computed: {
    totalSlides: function () {
      return this.carouselData.length - 3;
    }
  },
  methods: {
    slideRight() {
      // Checking if the slide reached end then resetting it to 0 else getting the next testimonials
      let idx = ++this.slide;
      if (idx > (this.carouselData.length - 1)) {
        idx = 0;
        this.slide = 0;
      }
      this.cardList =  this.carouselData.slice(idx, idx + 3);
    },
    slideLeft() {
      // Checking if the slide reached start then resetting it to 0 else getting the previous testimonials
      let idx = --this.slide;
      if (idx < 0) {
        idx = 0;
        this.slide = 0;
      }
      this.cardList =  this.carouselData.slice(idx, idx + 3);
    },
    // getting index of the card to be used to show the correct styles for that card
    getIdxByName(name) {
      return this.carouselData.findIndex(c => c.employeeName === name);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.testimonial-block {
  margin-bottom: 5rem;
  padding-top: 3rem;
  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
  }
  .header {
    font-size: 3rem;
    line-height: 110%;
    @include media-breakpoint-down(sm) {
      font-size: 2.375rem;
    }
  }
}
.testimonial-card {
  transition: all 0.5s ease;
  @include media-breakpoint-up(md) {
    min-width: 20rem;
  }
  min-width: 16rem;
  max-height: 32rem;
}
.testimonial-carousel-container {
  overflow-x: auto;
}
.carousel-item {
  max-width: 100% !important;
}
.arrow-controls-wrapper {
  margin-top: -2rem;
}
.control {
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 50%;
  border: 1px solid $gray-200;
  i {
    font-size: 1.5rem;
  }
}
.custom-indicator {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 0 0.25rem;
}
</style>