import gql from "graphql-tag";
import cliToolsMutations from "../mutations/cliTools";
import { getListQuery } from "../queries";

export default {
  ...cliToolsMutations,
  getOptions: gql`
    query cliOptions($cmdName: String!) {
      cliOptions(cmdName: $cmdName)
    }
  `,
  cmds: {
    onboardOrg: {
      label: "Onboard org/import users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "sendOnboardingInvites",
            type: "checkbox",
            label: "Send onboarding invite email to these users",
            fieldClasses: ["mb-5"],
          },
          {
            type: "label",
            hint: "Email invites if selected will be sent to all users in the file",
          },
          {
            type: "label",
            label:
              "Note - This tool would only create new users and won't update the data of existing users" +
              "<br>It can however be used to link benefits/policies to existing users and dependents at any time",
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    importECards: {
      label: "Import ecards zip",
      schema: {
        fields: [
          {
            model: "insurerFormat",
            type: "select",
            label: "Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
    fetchECards: {
      label: "Fetch E Cards",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        org,
        policyId: policy?.id,
        policy,
      }),
    },
    claimsBulkUpdate: {
      label: "Bulk Update Claims",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    exportChanges: {
      label: "Export user changes",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
      transformArgs: ({ org, batch, ...args }) => ({
        ...args,
        orgId: org?.id,
        batchId: batch?.id,
      }),
    },
    nukeCache: {
      label: "Delete cached content",
      schema: {
        fields: [
          {
            model: "cacheKeyToBeNuked",
            type: "select",
            label: "Cache category to be deleted",
            values: [],
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    removeUserBenefits: {
      label: "Remove User Benefits",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "benefitId",
            type: "input",
            inputType: "text",
            label: "User Benefit Id to remove",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({ ...args, orgId: org.id }),
    },
    sendBenefitDetailsEmails: {
      label: "Send Policy Parameters as Email",
      hint: "Sends emails to all users with an active policy whose status is not deleted",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "inclusionsBlockId",
            type: "input",
            inputType: "text",
            label: "Mailmodo inclusions blockId",
            required: true,
          },
          {
            model: "exclusionsBlockId",
            type: "input",
            inputType: "text",
            label: "Mailmodo exclusions blockId",
            required: true,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, policy, ...args }) => ({ ...args, orgId: org?.id, policyId: policy?.id }),
    },
    sendPortalActivatedEmails: {
      label: "Send Portal activated email",
      hint: "Sends Portal activated email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({ ...args, orgId: org?.id }),
    },
    sendEcardLinkedEmails: {
      label: "Send E-Card linked email",
      hint: "Sends portal E-Card linked email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({ ...args, orgId: org?.id }),
    },
  },
  cmdsAdminReviewChanges: {
    exportChanges: {
      label: "Export user changes",
      schema: {
        fields: [
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
    },
  },
};
