<template lang="pug">
div
  template(v-for="dynamicData in renderData")
    // Career Page Hero Block
    template(v-if="dynamicData.__typename === 'ComponentContentCareerHeroBlock'")
      career-hero-block(:careerHeroData="dynamicData")

    // Hero Block
    template(v-if="dynamicData.__typename === 'ComponentContentHero'")
      hero-block(:heroData="dynamicData")
      hr

    // Info Block
    template(v-if="dynamicData.__typename === 'ComponentContentInfoBlock'")
      info-block(:cardData="dynamicData")

    // Features Block
    template(v-if="dynamicData.__typename === 'ComponentContentFeaturesBlock'")
      features-block(:featuresData="dynamicData")

    // Career Page Team Block
    template(v-if="dynamicData.__typename === 'ComponentContentCareerTeamBlock'")
      career-team-block(:careerTeamData="dynamicData")

    // Career Page Testimonial Block
    template(v-if="dynamicData.__typename === 'ComponentContentCareerTestimonialBlock'")
      career-testimonial-block(:testimonialData="dynamicData")

    // Banner Block
    template(v-if="dynamicData.__typename === 'ComponentContentBannerBlock'")
      banner-block(:bannerData="dynamicData")
</template>

<script>
import CareerHeroBlock from "./CareerHeroBlock.vue";
import HeroBlock from "./HeroBlock.vue";
import InfoBlock from "./InfoBlock.vue";
import FeaturesBlock from "./FeaturesBlock.vue";
import CareerTeamBlock from "./CareerTeamBlock.vue";
import CareerTestimonialBlock from "./CareerTestimonialBlock.vue";
import BannerBlock from "./BannerBlock.vue";

export default {
  components: {
    HeroBlock,
    InfoBlock,
    FeaturesBlock,
    BannerBlock,
    CareerHeroBlock,
    CareerTeamBlock,
    CareerTestimonialBlock,
  },
  props: {
    renderData: {
      type: Array,
    },
  },
};
</script>

<style lang="scss"></style>