import gql from "graphql-tag";

export const samlVendorConfigFragment = gql`
  fragment SamlVendorConfig on SamlVendorConfig {
    id
    meta
    name
    ssoUrl
    entityId
    nameIdFormat
    requestSignatureAlgorithm
    acsUrl
    acsBinding
    signingCert
    wantAssertionsSigned
    updatedAt
  }
`;
