<template lang="pug">
.col-12.offset-md-1.col-md-10
  .team-block.position-relative.container-fluid
    .row
      .col-10.col-md-12.text-md-center.p-0
        .header.font-hergon-bold.mt-4 {{careerTeamData.heading}}
      .col-12.text-md-center.p-0
        .font-lg.font-weight-medium.text-gray-800.my-3 {{careerTeamData.subheading}}
    .team-image-container.p-0.d-flex.justify-content-md-center
      b-img(:src="careerTeamData.teamImage.url")
</template>

<script>
export default {
  components: {},
  props: {
    careerTeamData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.team-block {
  margin-bottom: 4rem;
  padding-top: 3rem;
  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }
  .header {
    font-size: 3rem;
    line-height: 110%;
    @include media-breakpoint-down(sm) {
      font-size: 2.375rem;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

</style>