import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";

export default {
  gqlType: "Job",
  fields: [
    "queueName",
    "commandName",
    "status",
    "timestamp",
    "email",
    "fileURL",
  ],
  listQuery: getListQuery("Job"),
  singleQuery: getSingleQuery("Job"),
  schema: {
    fields: [],
  },
  removeJobMutation: gql`
    mutation removeJob($jobId: ID) {
      removeJob(input: { jobId: $jobId }) {
        status
      }
    }
  `,
  transform: (n) => {
    return {
      ...n,
      commandName: n.name,
      timestamp: new Date(+n.timestamp).toLocaleString(),
    };
  },
}
