import gql from "graphql-tag";
import {
  samlVendorConfigFragment
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertSamlVendorConfig(
      $id: ID
      $meta: JSONObject
      $name: String
      $ssoUrl: String
      $entityId: String
      $nameIdFormat: String
      $requestSignatureAlgorithm: String
      $acsUrl: String
      $acsBinding: String
      $signingCert: String
      $wantAssertionsSigned: Boolean
    ) {
      upsertSamlVendorConfig(
        input: { 
          id: $id, 
          meta: $meta
          name: $name
          ssoUrl: $ssoUrl
          entityId: $entityId
          nameIdFormat: $nameIdFormat
          requestSignatureAlgorithm: $requestSignatureAlgorithm
          acsUrl: $acsUrl
          acsBinding: $acsBinding
          signingCert: $signingCert
          wantAssertionsSigned: $wantAssertionsSigned 
        }
      ) {
        samlVendorConfig {
          ...SamlVendorConfig
        }
      }
    }
    ${samlVendorConfigFragment}
  `,
}
