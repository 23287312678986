import { orgStatusSchema } from "../constants";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Org Name",
        required: true,
      },
      {
        model: "address",
        type: "input",
        inputType: "text",
        label: "Address",
        required: true,
      },
      {
        model: "pincode",
        type: "input",
        inputType: "text",
        label: "Pincode",
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "grades",
              type: "array",
              label: "Org Grades",
              hint: "Empty the box and save to remove a grade\nIf the org only has 1 grade, please set it as “DEFAULT” (with caps)",
              schema: {
                fields: [
                  {
                    model: "grade",
                    type: "input",
                    inputType: "text",
                  },
                ],
              },
            },
            {
              model: "gstin",
              type: "input",
              inputType: "text",
              label: "GSTIN",
            },
            {
              model: "bank_acc",
              type: "input",
              inputType: "text",
              label: "Bank Account Number",
            },
            {
              model: "bank_ifsc",
              type: "input",
              inputType: "text",
              label: "Bank IFSC Code",
            },
            {
              model: "bank_bene",
              type: "input",
              inputType: "text",
              label: "Beneficiary Name",
            },
            {
              model: "contact_person",
              type: "input",
              inputType: "text",
              label: "Contact Person",
            },
            {
              model: "contact_number",
              type: "input",
              inputType: "text",
              label: "Contact Number",
            },
            {
              model: "batchConfig",
              type: "object",
              label: "Batch Configuration",
              schema: {
                fields: [
                  {
                    model: "recurring",
                    type: "select",
                    label: "Recurring Config",
                    values: [
                      "monthly",
                      /* TODO: finish the implemenation for below cases.
                    "bi-weekly", "weekly", "daily"
                    */
                    ],
                    required: true,
                  },
                  {
                    model: "custom",
                    type: "array",
                    itemContainerComponent: "form-container",
                    label: "Custom Batch Configurations",
                    items: {
                      getTitle: (idx, item) => `Custom Batch ${idx + 1}`,
                      type: "object",
                      schema: {
                        fields: [
                          {
                            model: "startingAt",
                            type: "datepicker",
                            label: "Batch Submission start date",
                            min: "1900-01-01",
                            max: "2100-12-31",
                            required: true,
                          },
                          {
                            model: "endingAt",
                            type: "datepicker",
                            label: "Batch Submission end date",
                            min: "1900-01-01",
                            max: "2100-12-31",
                            required: true,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      {
        model: "status",
        type: "select",
        label: "Status",
        values: orgStatusSchema,
        required: false,
      },
    ],
  },
}
