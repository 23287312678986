<template lang="pug">
.hero-container.row.position-relative.w-100.mt-4.pt-4.pb-5.mb-5.d-flex.flex-column.flex-md-row.align-items-center.px-4.px-md-0
  .hero-title.col-12.col-md-7.col-lg-6
    img.mt-5(:src="titleTopIcon || require('@/assets/images/star-yellow.svg')")
    h1.mt-4  {{ truncateTitleAfter }}
      span.px-4.py-3(v-if="heroData.circleWord") {{ heroData.circleWord }} 
      | {{ truncateTitleBefore }}
  .hero-right.col-12.col-md-5.col-lg-6.ml-auto
    b-img(:src="heroRightImage || require('@/assets/images/colleagues.svg')")
</template>

<script>
export default {
  components: {},
  props: {
    heroData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    titleTopIcon() {
      return this.heroData?.titleTopIcon?.url;
    },
    heroRightImage() {
      return this.heroData?.heroRightImage?.url;
    },
    truncateTitleBefore() {
      return this.heroData?.title.slice(this.heroData?.title.indexOf(this.heroData?.circleWord) + this.heroData?.circleWord.length);
    },
    truncateTitleAfter() {
      return this.heroData?.title.slice(0, this.heroData?.title.indexOf(this.heroData?.circleWord));
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.hero-container h1 {
  font-size: 4.5rem;
  line-height: 103%;
  span {
    background-image: url("~@/assets/images/text-circle.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
  }
}

.hero-right {
  img {
    float: right;
    max-height: 22rem;
    max-width: 28.75rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

</style>