import gql from "graphql-tag";

export const hospitalFragment = gql`
  fragment Hospital on Hospital {
    id
    name
    address
    city
    pincode
  }
`;
