export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Name",
        required: true,
      },
      {
        model: "ssoUrl",
        type: "input",
        inputType: "text",
        label: "SSO URL",
        required: true,
      },
      {
        model: "entityId",
        type: "input",
        inputType: "text",
        label: "Entity Id",
        required: true,
      },
      {
        model: "nameIdFormat",
        type: "input",
        inputType: "text",
        label: "Name Id Format",
        required: true,
      },
      {
        model: "requestSignatureAlgorithm",
        type: "input",
        inputType: "text",
        label: "Signature Algrithm",
      },
      {
        model: "acsUrl",
        type: "input",
        inputType: "text",
        label: "ACS URL",
      },
      {
        model: "acsBinding",
        type: "select",
        label: "ACS Binding",
        values: ["REDIRECT", "POST"],
        required: true,
      },
      {
        model: "signingCert",
        type: "input",
        label: "Signing Cert",
        inputType: "text",
        required: true,
      },
      {
        model: "wantAssertionsSigned",
        label: "Want Assertions Signed?",
        type: "checkbox",
        default: false,
      },
    ],
  },
}
