import gql from "graphql-tag";

export const claimFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      id
      name
      displayName
      email
      org {
        id
        name
      }
      meta
    }
    policy {
      id
      type
      name
      insurer {
        id
        name
      }
    }
    dependent {
      id
      name
      relation
    }
    status
    updatedAt
    createdAt
    displayContent
    timeline
    history {
      id
      claimId
      meta
      createdAt
      status
      displayContent
    }
  }
`;
