import { getListQuery } from "../queries";
import utils from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Policy Type",
        values: ["gmc", "gpa", "gtl", "covid", "workmen", "topup", "super-topup", "dcl", "ppc"],
        fieldClasses: ["form-select"],
      },
      {
        model: "insurer",
        type: "id-selector",
        queryName: "insurers",
        gqlQuery: getListQuery("Insurer"),
        getSubTitle: (i) => i.type,
        label: "Insurer",
        required: true,
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Policy Name",
      },
      {
        model: "isPolicy",
        label: "Is Policy",
        type: "checkbox",
        default: false,
        visible: false,
      },
      {
        type: "checklist",
        model: "restrictedFields",
        label: "Restricted Fields",
        values: ["name", "dob", "gender"],
        listBox: true,
      },
      {
        model: "policyClaimContent",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        queryVariables: () => ({
          filter: {
            collectionType: "Benefit",
          },
        }),
        label: "Policy Details Policy Claim Content (from strapi)",
        nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
        getLink: utils.getLinkMapperForStrapi("Benefit"),
      },
      {
        model: "coverageParameters",
        type: "object",
        label: "Coverage Parameters (from strapi)",
        schema: {
          fields: [
            {
              model: "inclusions",
              type: "array",
              itemContainerComponent: "form-container",
              label: "Coverage Inclusions",
              items: {
                getTitle: (idx, item) =>
                  item?.parameter?.meta?.title
                    ? `Inclusion ${idx + 1}: ${item.parameter.meta.title}`
                    : "[Untitled Inclusion]",
                type: "object",
                schema: {
                  fields: [
                    {
                      model: "parameter",
                      type: "id-selector",
                      gqlQuery: getListQuery("StrapiContent"),
                      label: "Coverage Parameter Slug",
                      required: true,
                      getLink: utils.getLinkMapperForStrapi("CoverageParameter"),
                      getPreview: (item) =>
                        item ? `<strong>${item.meta.title}</strong><br><div>${item.meta.text}</div>` : "",
                      nodesFromData: utils.getOptionsMapperForStrapi("strapiContents", "inclusion"),
                      queryVariables: () => ({
                        filter: {
                          collectionType: "CoverageParameter",
                        },
                        limit: 5,
                        offset: 0,
                      }),
                    },
                  ],
                },
              },
            },
            {
              model: "exclusions",
              type: "array",
              itemContainerComponent: "form-container",
              label: "Coverage Exclusions",
              items: {
                getTitle: (idx, item) =>
                  item?.parameter?.meta?.title
                    ? `Exclusion ${idx + 1}: ${item.parameter.meta.title}`
                    : "[Untitled Exclusion]",
                type: "object",
                schema: {
                  fields: [
                    {
                      model: "parameter",
                      type: "id-selector",
                      gqlQuery: getListQuery("StrapiContent"),
                      label: "Coverage Parameter Slug",
                      getLink: utils.getLinkMapperForStrapi("CoverageParameter"),
                      required: true,
                      getPreview: (item) =>
                        item ? `<strong>${item.meta.title}</strong><br><div>${item.meta.text}</div>` : "",
                      nodesFromData: utils.getOptionsMapperForStrapi("strapiContents", "exclusion"),
                      queryVariables: () => ({
                        filter: {
                          collectionType: "CoverageParameter",
                        },
                        limit: 5,
                        offset: 0,
                      }),
                    },
                  ],
                },
              },
            },
          ],
        },
      },
      {
        model: "meta",
        type: "object",
        label: "Policy Meta",
        schema: {
          fields: [
            {
              model: "policyNumber",
              type: "input",
              inputType: "text",
              label: "Policy Number",
            },
            {
              type: "switch",
              label: "Do you want to hide the Sum Insured?",
              model: "hideSi",
            },
            {
              type: "switch",
              label: "Do you want to hide the Policy Document?",
              model: "hidePolicyDoc",
            },
            {
              model: "startDate",
              type: "datepicker",
              label: "Policy start date",
              min: "1900-01-01",
              max: "2100-12-31",
              required: false,
              hint: "Start date must be same or before the end date!",
            },
            {
              model: "endDate",
              type: "datepicker",
              label: "Policy end date",
              min: "1900-01-01",
              max: "2100-12-31",
              required: true,
            },
            {
              model: "depositBalance",
              type: "input",
              inputType: "number",
              label: "Cash Deposit Balance",
            },
            {
              model: "depositBalanceDate",
              type: "datepicker",
              min: "1900-01-01",
              max: "2100-12-31",
              label: "CD Balance Date",
            },
            {
              model: "totalClaimsAmount",
              type: "input",
              inputType: "number",
              label: "Total Claims Amount",
            },
            {
              model: "totalClaimsDate",
              type: "datepicker",
              min: "1900-01-01",
              max: "2100-12-31",
              label: "Claims Amount Date",
            },
          ],
        },
      },
      {
        model: "tpa",
        type: "id-selector",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        getSubTitle: (u) => u.name,
        label: "Tpa",
      },
    ],
  },
};
