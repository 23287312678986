import { getListQuery, getSingleQuery } from "../queries";
import policiesSchema from "../schemas/policies";
import policiesMutations from "../mutations/policies";

export default {
  gqlType: "Benefit",
  fields: ["name", "type", "insurerName", "tpaName"],
  singleQuery: getSingleQuery("Benefit"),
  listQuery: getListQuery("Benefit"),
  ...policiesSchema,
  ...policiesMutations,
  transform: (n, write = false) => {
    let policySpecificInfo = {};
    if (n.insurer) {
      policySpecificInfo = {
        insurerId: n.insurer.id,
        insurerName: n.insurer.name,
        tpaName: n.tpa?.name || "-",
        isPolicy: true,
      };
    } else {
      n.isPolicy = false;
    }
    if (n.coverageParameters) {
      // Handle coverage parameters
      n.meta.inclusionStrapiIds = n.coverageParameters.inclusions?.map(
        (p) => p?.parameter?.meta?.id
      );
      n.meta.exclusionStrapiIds = n.coverageParameters.exclusions?.map(
        (p) => p?.parameter?.meta?.id
      );
      // Removing null values
      n.meta.inclusionStrapiIds = n.meta.inclusionStrapiIds?.filter((p) => p);
      n.meta.exclusionStrapiIds = n.meta.exclusionStrapiIds?.filter((p) => p);

      n.coverageParameters.inclusions?.forEach(
        ({ parameter }) =>
          parameter &&
          (parameter.name = `${parameter.slug} (${parameter.id})`)
      );
      n.coverageParameters.exclusions?.forEach(
        ({ parameter }) =>
          parameter &&
          (parameter.name = `${parameter.slug} (${parameter.id})`)
      );
    }
    if(n.policyClaimContent?.meta?.id) {
      n.policyClaimContent = {
        ...n.policyClaimContent,
        id: n.policyClaimContent?.meta?.id,
        name: `${n.policyClaimContent?.slug} (${n.policyClaimContent?.meta?.id})`,
      };
    }
    return {
      ...n,
      ...policySpecificInfo,
      tpaId: n.tpa?.id,
      meta: {
        ...n.meta,
        policyClaimContentStrapiId: n.policyClaimContent?.meta?.id || n.policyClaimContent?.id,
        insurerName: n.insurer?.name,
      },
    };
  },
  getEdgesFromData: (data) => data.benefits.edges,
  getTotalNumberOfPolicies: (benefits) => {
    let count = 0;
    benefits.forEach((benefit) => {
      if (benefit.node.isPolicy) {
        count++;
      }
    });
    return count;
  },
}
