export const challengeStatusSchema = {
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  DELETED: "DELETED",
};

export const orgStatusSchema = [
  { id: "ACTIVE", name: "Active" },
  { id: "DELETED", name: "Deleted" },
  { id: "PROSPECT", name: "Prospect" },
  { id: "INACTIVE", name: "Inactive" },
];

export const userRoles = ["guest", "user", "org_member", "org_admin", "account_admin", "admin", "prospect"];

export const policyTypes = {
  "no-policy-defined": {
    title: "Health Insurance",
    subtitle: "Just a little longer before your policy is activated.",
    image: "nopolicy",
    icon: "hospital",
    type: "no-policy-defined",
  },
  gmc: {
    title: "Health Insurance",
    subtitle: "Your group mediclaim insurance policy",
    tagline: "GMC - Group Mediclaim Policy",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
  },
  gpa: {
    title: "Accident Coverage",
    subtitle: "Your group personal accident insurance policy details",
    tagline: "GPA - Group Personal Accident",
    icon: "wheelchair",
    image: "hospital-bed",
    color: "mustard-600",
  },
  gtl: {
    title: "Life Insurance",
    subtitle: "Your group term life insurance policy",
    tagline: "TL - Term Life Insurance",
    icon: "bed",
    image: "umbrella",
    color: "malibu-600",
  },
  covid: {
    title: "COVID Protection",
    subtitle: "Wearing a mask and washing your hands is still the best policy",
    tagline: "COV19 - Covid Coverage Policy",
    icon: "covid",
    image: "hospital-bed",
    color: "purple-400",
  },
  topup: {
    title: "Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Top-up",
    icon: "add",
    color: "teal-700",
  },
  "super-topup": {
    title: "Super Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Super Top-up",
    icon: "add",
  },
  dental: {
    title: "Dental Care",
    subtitle: "Make sure the smile stays on",
    tagline: "Dental Care Policy",
    icon: "teeth",
    image: "dental-tools",
    color: "blue-400",
  },
  workmen: {
    title: "Workmen Compensation",
    subtitle: "Your workmen compensation insurance policy details",
    tagline: "Workmen Compensation Insurance Policy",
    icon: "wheelchair",
    image: "hospital-bed",
    color: "gray-900",
  },
  dcl: {
    title: "Life Insurance",
    subtitle: "Your direct contractual liability policy details",
    tagline: "CL - Contractual Liability Insurance",
    icon: "bed",
    image: "umbrella",
    color: "malibu-600",
  },
  ppc: {
    title: "Parental Policy",
    subtitle: "Health insurance policy for your parents",
    tagline: "Health insurance policy for your parents",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
  },
  addOnCards: {
    "topup-card": {
      title: "Looking for extra\ncoverage options?",
      subtitle: "You can purchase a super top-up over your work policy and expand your coverage by 20 lacs",
      tagline: "Top up health insurance policy",
      btnText: "Learn more about supertop ->",
      image: "new-product-bg-1.svg",
    },
  },
};

export const benefitTypes = {
  "partner-signup": {
    subtitle: "Employee Benefits",
  },
  other: {
    subtitle: "Other",
  },
  "free-coupon": {
    subtitle: "Free Coupon",
  },
};

export const claimsStatus = {
  CLAIM_INTIMATED: "CLAIM_INTIMATED",
  DOCUMENTS_REQUESTED: "DOCUMENTS_REQUESTED",
  DOCUMENTS_RECEIVED: "DOCUMENTS_RECEIVED",
  UNDER_QUERY: "UNDER_QUERY",
  SUBMITTED: "SUBMITTED",
  UNDER_PROGRESS: "UNDER_PROGRESS",
  UNDER_QUERY_TPA: "UNDER_QUERY_TPA",
  APPROVED: "APPROVED",
  CLAIM_SETTLED: "CLAIM_SETTLED",
  REJECTED: "REJECTED",
  DELETED: "DELETED",
};

export const claimStatusList = Object.keys(claimsStatus).map((key) => claimsStatus[key]);

export const claimDocuments = {
  CLAIM_FORM_PART_A: "Claim form: Part A",
  CLAIM_FORM_PART_B: "Claim form: Part B",
  ADHAAR_PRIMARY: "Adhaar Card of Primary Insured",
  ADHAAR_PATIENT: "Adhaar Card of the Patient",
  PAN_PRIMARY: "PAN Card of Primary Insured",
  CANCELLED_CHEQUE: "Cancelled cheque with name of primary insured",
  DISCHARGE_SUMMARY: "Detailed discharge summary",
  INVESTIGATION_REPORT: "Investigation reports",
  HOSPITAL_BILL: "Main hospital bill",
  PAID_RECIEPT: "Receipt of the amount paid",
  MED_INV_PRESCRIPTION: "Prescriptions for medicines & investigations",
  PHARMACY_BILL: "Pharmacy bills for medicines",
  INVESTIGATION_BILL: "Investigation bills",
};

export const claimDocumentsList = Object.keys(claimDocuments).map((key) => claimDocuments[key]);

export const claimDocumentSegments = {
  "Insurance Claim Forms": [claimDocuments.CLAIM_FORM_PART_A, claimDocuments.CLAIM_FORM_PART_B],
  "Identification Documents": [
    claimDocuments.ADHAAR_PRIMARY,
    claimDocuments.ADHAAR_PATIENT,
    claimDocuments.CANCELLED_CHEQUE,
    claimDocuments.PAN_PRIMARY,
  ],
  "Medical Reports & Prescriptions": [claimDocuments.DISCHARGE_SUMMARY, claimDocuments.INVESTIGATION_REPORT],
  "Bills & Receipts": [
    claimDocuments.HOSPITAL_BILL,
    claimDocuments.PAID_RECIEPT,
    claimDocuments.MED_INV_PRESCRIPTION,
    claimDocuments.PHARMACY_BILL,
    claimDocuments.INVESTIGATION_BILL,
  ],
};

export const usersStatusList = ["DELETED", "UPDATED", "CREATED", "SUSPENDED", "DEACTIVATED"];

export const acceptedFamilyDefinitionsList = [
  "E",
  "ES2C",
  "ES2CP",
  "ES2CP/IL",
  "ES2CP+IL",
  "ES3C",
  "ES3CP",
  "ES3CP/IL",
  "ES3CP+IL",
  "ES4C",
  "ES4CP",
  "ES4CP/IL",
  "ES4CP+IL",
  "ES5C",
  "ES5CP",
  "ES5CP/IL",
  "ES5CP+IL",
  "ES6C",
  "ES6CP",
  "ES6CP/IL",
  "ES6CP+IL",
  "P",
  "P/IL",
];
