import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import {
  dependentsFragment,
  userBenefitsFragment,
} from "../fragments";
import usersSchema from "../schemas/users";
import usersMutations from "../mutations/users";

export default {
  gqlType: "User",
  fields: ["empId", "email", "name", "gender", "orgName", "status"],
  singleQuery: getSingleQuery("User"),
  listQuery: getListQuery("User"),
  ...usersSchema,
  ...usersMutations,
  transform: (n) => {
    return {
      ...n,
      orgName: n.org ? n.org.name : null,
      orgId: n.org ? n.org.id : null,
    };
  },
  transformUpsertMutationVariables: (variables) => {
    // TODO: Remove and shift to a cleaner solution ASAP.
    const {
      id,
      org,
      name,
      displayName,
      email,
      gender,
      dob,
      roles,
      oldPassword,
      password,
      meta,
      dependents,
      status,
    } = { ...variables };
    const trimmedDependents = dependents?.map((dependent) => {
      const { id, name, dob, relation, gender } = { ...dependent };
      return {
        id,
        name,
        dob,
        relation,
        gender,
      };
    });
    return {
      id,
      orgId: org.id,
      name,
      displayName,
      email,
      gender,
      dob,
      roles,
      oldPassword,
      password,
      meta,
      dependents: trimmedDependents,
      status,
    };
  },
  transformAddBenefit: (data) => data.addUserToBenefit.userBenefitEdge,
  transformDeleteBenefit: (data) => data.deleteUserFromBenefit.deletedCount,
  benefitsFragment: userBenefitsFragment,
  benefitsFragmentName: "UserBenefits",
  transformAddDependent: (data) => data.upsertDependent.dependent,
  transformDeleteDependent: (data) => data.deleteDependent.deletedCount,
  dependentsFragment: dependentsFragment,
  dependentsFragmentName: "UserDependents",
  impersonateUser: gql`
    query getUserToken($id: ID!) {
      getUserToken(id: $id)
    }
  `,
}
