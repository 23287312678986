<template lang="pug">
.info-block.col-12.offset-md-1.col-md-10.px-0
  .d-flex.justify-content-between.py-4
    .col-md-3.col-lg-2.px-0
      h1.hb1.pb-4 {{ cardData.question }}
    .col-md-8.col-lg-8.px-0.align-self-start
      .answer-container.pb-3(v-for="(answer, idx) in cardData.answer", :key="idx")
        h4.hb4 {{ answer.title }}
        .answer.font-lg.font-inter.text-gray-800(v-html="answer.text")
  .info-block-divider
</template>

<script>
export default {
  components: {},
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.info-block {
  p {
    font-size: 1rem;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.info-block-divider {
  height: 0;
  border: 1.2px solid #F2F6FF;
}
</style>