<template lang="pug">
.hero-block.position-relative.container-fluid
  .content.row.ml-auto.mr-auto
    .col-12.col-md-6.col-lg-5.hero-left
      b-img.mt-5(:src="careerHeroData.headingIcon.url")
      .header.font-hergon-bold.mt-4 {{careerHeroData.heading}}
      .subheader.font-lg.font-weight-medium.text-gray-500.my-3 {{careerHeroData.subheading}}
      n-button.cta-button.mt-4.mt-md-2.mb-0.mb-md-5(
        :buttonText="careerHeroData.buttonText",
        variant="warning",
        :href="careerHeroData.buttonLink"
        size="md")
    .col-12.col-md-6.align-self-center.align-self-md-center.hero-right.mt-5.ml-auto
      .hero-right-image
        b-img(:src="careerHeroData.imageRight.url")
      b-img#rotating-spike.spike.position-absolute.d-none.d-md-block(:src="require(`@/assets/images/spike.svg`)")
  .hero-stats.col-12.rounded-3.w-90.py-2.px-2.backdrop-blur
    .row.stat-container.position-relative
      .col-6.col-md-3.pl-4.pl-md-0.my-md-4.d-flex.flex-column.align-items-start.align-items-md-center(
        v-for="(stat, idx) in careerHeroData.stat" :class="`${idx !== careerHeroData.stat.length-1 ? 'light-border' : ''}`",
        :key="idx"
      )
        .stat-title.font-inter.font-sm.font-weight-semibold.text-mustard-900.py-2 {{stat.title}}
        .stat-data.h3.hb3.text-gray-900 {{stat.value}}

</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  components: {
    NButton,
  },
  props: {
    careerHeroData: {
      type: Object,
      default: () => {},
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      let image = document.getElementById("rotating-spike");
      image.style.transform = "rotate(" + window.pageYOffset/2 + "deg)";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.hero-block {
  background-color: $tiber;
  color: $white;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-top: -5rem;
  margin-bottom: 8rem;
  padding-top: 10rem;
  .content {
    padding: 0 6.25rem;
  }
  @media (min-width: 767px) and (max-width: 1156px) {
    padding-top: 8rem;
    padding-bottom: 4rem;
    .content {
      padding: 0 3rem;
    }
  }
  @include media-breakpoint-down(md) {
    padding-top: 5rem;
    .content {
      padding: 0;
    }
  }
  .header {
    font-size: 3rem;
    line-height: 110%;
    @media (min-width: 767px) and (max-width: 1156px) {
      line-height: 105%;
      font-size: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2.375rem;
    }
  }
}

.light-border {
  border-right: 2px solid rgba(0, 0, 0, 0.04);
}
.hero-left {
  img {
    max-width: 1.75rem;
    max-height: 1.75rem;
  }
  .cta-button {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
.hero-right-image {
  position: relative;
  img {
    bottom: 0;
    float: right;
    right: 0;
    max-height: 100%;
    max-width: 100%;
    border-radius: 14rem 14rem 0 0;
    @include media-breakpoint-down(sm) {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
}
.spike {
  right: -6rem;
  bottom: 2.5rem;
  float: right;
  @media (min-width: 1500px) {
    right: -4.5rem;
    bottom: 2.5rem;
  }
  @media (min-width: 767px) and (max-width: 1155px) {
    right: -2rem;
    bottom: -2rem;
  }
}
.hero-stats {
  width: 95%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 0.625rem;
  position: absolute;
  bottom: -5rem;
  .stat-title {
    text-transform: uppercase;
  }
  @include media-breakpoint-down(sm) {
    .stat-title {
      font-size: 0.625rem;
    }
  }
  @include media-breakpoint-up(sm) {
    width: 90%;
    .row {
      padding: 1rem 5rem;
    }
    .stat-data {
      font-size: 2.375rem;
    }
  }
}
.backdrop-blur {
  background: rgba(251, 251, 251, 1);
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    background: rgba(251, 251, 251, 0.86);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
  }
}
</style>