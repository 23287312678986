import gql from "graphql-tag";
import {
  userChangeFragment,
} from "../fragments";

export default {
  updateUserOrDependentInfo: gql`
    mutation updateUserOrDependentInfo(
      $id: ID
      $orgId: ID
      $userId: ID!
      $changedUserInfo: JSONObject
      $changedDependentInfo: JSONObject
      $changedBenefitInfo: JSONObject
      $meta: JSONObject
      $dependentId: ID
      $status: String
      $type: String
    ) {
      updateUserOrDependentInfo(
        input: {
          id: $id
          orgId: $orgId
          meta: $meta
          status: $status
          type: $type
          userId: $userId
          dependentId: $dependentId
          changedUserInfo: $changedUserInfo
          changedDependentInfo: $changedDependentInfo
          changedBenefitInfo: $changedBenefitInfo
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  upsertMutation: gql`
    mutation upsertUserChange(
      $id: ID
      $orgId: ID
      $userId: ID!
      $changedUserInfo: JSONObject
      $changedDependentInfo: JSONObject
      $changedBenefitInfo: JSONObject
      $meta: JSONObject
      $dependentId: ID
      $benefitId: ID!
      $status: String
      $type: String
    ) {
      upsertUserChange(
        input: {
          id: $id
          orgId: $orgId
          meta: $meta
          status: $status
          type: $type
          userId: $userId
          dependentId: $dependentId
          benefitId: $benefitId
          changedUserInfo: $changedUserInfo
          changedDependentInfo: $changedDependentInfo
          changedBenefitInfo: $changedBenefitInfo
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  setUserChangesStatus: gql`
    mutation setUserChangesStatus(
      $for: String!
      $status: String!
      $statusFilter: String
      $userChangeIds: [ID]
      $orgId: ID
      $typeFilter: [String]
      $batchIds: [String]
    ) {
      setUserChangesStatus(
        input: {
          for: $for
          status: $status
          statusFilter: $statusFilter
          orgId: $orgId
          userChangeIds: $userChangeIds
          typeFilter: $typeFilter
          batchIds: $batchIds
        }
      ) {
        userChanges {
          ...UserChange
        }
        message
      }
    }
    ${userChangeFragment}
  `,
  addBenefitToUserAndDependentsMutation: gql`
    mutation addBenefitToUserAndDependentsMutation(
      $orgId: ID!
      $userId: ID!
      $dependents: Array
      $benefitId: ID!
      $meta: JSONObject
      $status: String
      $type: String
    ) {
      addBenefitToUserAndDependentsMutation(
        input: {
          orgId: $orgId
          userId: $userId
          dependents: $dependents
          benefitId: $benefitId
          meta: $meta
          status: $status
          type: $type
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
     transferUserChangeBetweenBatches: gql`
     mutation transferUserChangeBetweenBatches(
       $newBatchId: ID
       $userChangeIds: [ID]
     ) {
       transferUserChangeBetweenBatches(
         input: {
           newBatchId: $newBatchId
           userChangeIds: $userChangeIds
         }
       ) {
         userChanges {
           ...UserChange
         }
       }
     }
     ${userChangeFragment}
   `,
   updateBatchPremiumData: gql`
    mutation updateBatchPremiumData(
      $batchId: ID!
      $premiumData: JSONObject!
    ) {
      updateBatchPremiumData(
        input: {
          batchId: $batchId
          premiumData: $premiumData
        }
      ) {
          premiumData
        }
     }
   `,
}
