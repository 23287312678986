import { benefitFragment } from ".";
import gql from "graphql-tag";

export const orgBenefitFragment = gql`
  fragment OrgBenefitEdge on OrgBenefitEdge {
    node {
      ...Benefit
    }
    meta
  }
  ${benefitFragment}
`;

export const orgBenefitsFragment = gql`
  fragment OrgBenefits on Org {
    id
    benefits {
      ...OrgBenefitEdge
    }
  }
  ${orgBenefitFragment}
`;

export const orgFragment = gql`
  fragment Org on Org {
    id
    name
    address
    pincode
    meta
    status
    benefits {
      ...OrgBenefitEdge
    }
  }
  ${orgBenefitFragment}
`;
