import gql from "graphql-tag";
import {
  benefitFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $insurerId: ID
      $tpaId: ID
      $isPolicy: Boolean
      $restrictedFields: [String]
      $type: String
      $name: String
      $meta: JSONObject
    ) {
      upsertBenefit(
        input: {
          id: $id
          insurerId: $insurerId
          isPolicy: $isPolicy
          tpaId: $tpaId
          restrictedFields: $restrictedFields
          type: $type
          name: $name
          meta: $meta
        }
      ) {
        benefit {
          ...Benefit
        }
      }
    }
    ${benefitFragment}
  `,
}
