<template lang="pug">
.col-12.offset-md-1.col-md-11
  .testimonial-block.position-relative
    .row.mb-5
      .col-10.col-md-6.mr-auto.p-0
        .header.font-hergon-bold.mt-4 {{testimonialData.heading}}
        .font-lg.font-weight-medium.text-gray-800.my-3 {{testimonialData.subheading}}
      responsive-carousel(:carouselData="testimonialData.careerTestimonial")
</template>

<script>
import ResponsiveCarousel from "./components/ResponsiveCarousel.vue";

export default {
  components: {
    ResponsiveCarousel,
  },
  props: {
    testimonialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.testimonial-block {
  margin-bottom: 5rem;
  padding-top: 3rem;
  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
  }
  .header {
    font-size: 3rem;
    line-height: 110%;
    @include media-breakpoint-down(sm) {
      font-size: 2.375rem;
    }
  }
}
</style>