import { getListQuery, getSingleQuery } from "../queries";
import {
  orgBenefitsFragment,
} from "../fragments";
import orgsSchema from "../schemas/orgs";
import orgsMutations from "../mutations/orgs";

export default {
  gqlType: "Org",
  fields: ["name", "address", "pincode"],
  singleQuery: getSingleQuery("Org"),
  listQuery: getListQuery("Org"),
  ...orgsSchema,
  ...orgsMutations,
  transform: (n, write = false) => {
    n.benefits?.forEach((orgBenefitEdge) => {
      // Transform grademapping object to array like in policy transform
      orgBenefitEdge.meta.gradeMappingArray = orgBenefitEdge.meta
        .gradeMapping
        ? Object.entries(orgBenefitEdge.meta.gradeMapping).map(
            ([grade, info]) => ({ grade, ...info })
          )
        : [];
    });
    if(write) delete n.benefits;
    return { ...n };
  },
  transformExportResp: (data) => data.exportEmployeeData.data.exportFile,
  emailSyncSchema: {
    fields: [
      {
        model: "orgs",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Organizations",
        hint: "Leave empty to re-sync contact lists of all orgs",
        multiple: true,
        showSelected: false,
      },
      {
        model: "meta",
        type: "object",
        label: "Additional data (if any)",
      },
    ],
  },
  benefitsFragment: orgBenefitsFragment,
  benefitsFragmentName: "OrgBenefits",
  transformAddBenefit: (data) => data.addOrgToBenefit.orgBenefitEdge,
  transformDeleteBenefit: (data) => data.deleteOrgFromBenefit.deletedCount,
}
