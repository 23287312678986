import gql from "graphql-tag";
import {
  strapiContentFragment,
  tpaFragment,
  benefitFragment,
  userChangeFragment,
  userFragment,
  challengeTemplateFragment,
  orgFragment,
  claimFragment,
  integrationFragment,
  formFragment,
  insurerFragment,
  hospitalFragment,
  contentFragment,
  prospectFragment,
  jobFragment,
  userChangeBatchFragment,
  orgChallengeFragment,
  userChallengeFragment,
  segmentFragment,
  userSegmentFragment,
  calendarEventFragment,
  orgPropertyFragment,
  samlVendorConfigFragment,
} from "./fragments";

export const gqlResourceConfig = {
  User: { plural: "users", fragment: userFragment },
  Org: { plural: "orgs", fragment: orgFragment },
  Insurer: { plural: "insurers", fragment: insurerFragment },
  Tpa: { plural: "tpas", fragment: tpaFragment },
  Hospital: { plural: "hospitals", fragment: hospitalFragment },
  Form: { plural: "forms", fragment: formFragment },
  Claim: { plural: "claims", fragment: claimFragment },
  Integration: { plural: "integrations", fragment: integrationFragment },
  Content: { plural: "content", fragment: contentFragment },
  Benefit: { plural: "benefits", fragment: benefitFragment },
  UserChange: { plural: "userChanges", fragment: userChangeFragment },
  Prospect: { plural: "prospects", fragment: prospectFragment },
  Job: { plural: "jobs", fragment: jobFragment },
  StrapiContent: { plural: "strapiContents", fragment: strapiContentFragment },
  OrgProperty: { plural: "orgProperties", fragment: orgPropertyFragment },
  UserChangeBatch: {
    plural: "userChangeBatches",
    fragment: userChangeBatchFragment,
  },
  ChallengeTemplate: {
    plural: "challengeTemplates",
    fragment: challengeTemplateFragment,
  },
  OrgChallenge: { plural: "orgChallenges", fragment: orgChallengeFragment },
  UserChallenge: { plural: "userChallenges", fragment: userChallengeFragment },
  Segment: { plural: "segments", fragment: segmentFragment },
  UserSegment: { plural: "userSegments", fragment: userSegmentFragment },
  SamlVendorConfig: { plural: "samlVendorConfigs", fragment: samlVendorConfigFragment },
  CalendarEvent: { plural: "calendarEvents", fragment: calendarEventFragment },
};

export const getListQuery = (gqlType) => {
  if (
    [
      "User",
      "Claim",
      "Benefit",
      "StrapiContent",
      "ChallengeTemplate",
      "OrgChallenge",
      "UserChallenge",
      "OrgProperty",
      "Segment",
      "CalendarEvent",
      "UserSegment",
      "SamlVendorConfig"
    ].includes(gqlType)
  ) {
    return getFilteredListQuery(gqlType);
  }
  const { plural, fragment } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export const getFilteredListQuery = (gqlType) => {
  const { plural, fragment } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $filter: JSONObject
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        filter: $filter
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export const getSingleQuery = (gqlType) => {
  const { fragment } = gqlResourceConfig[gqlType];
  const query = gql`query ${gqlType}Single($id: ID!){
    node(id: $id){ ...${gqlType} }
  }
  ${fragment}`;
  return query;
};

export const getECardFormats = () => {
  const query = gql`
    query eCardFormats {
      eCardFormats
    }
  `;
  return query;
};
