<template lang="pug">
.col-12.offset-md-1.col-md-10.px-0
  .banner-container.row.position-relative.w-90
    .banner-content.col-12.col-lg-8.d-flex.flex-column.align-items-center.align-items-lg-start
      .banner-title.font-hergon-bold.text-center.text-lg-left {{ bannerData.heading }}
      span.font-lg.font-inter.text-gray-800.pt-3.pt-md-0.text-center.text-lg-left.mt-md-4.mb-md-2 {{ bannerData.subheading }}
      n-button.cta-button.mt-5.mt-md-3.mb-0.mb-md-5(
        :buttonText="`${bannerData.buttonText} ->`",
        :href="`${bannerData.buttonLink}`",
        variant="warning",
        size="md")
    .banner-image.col-12.col-lg-4.ml-auto.p-0
      b-img(:src="bannerData.bannerImage.url")
</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  components: {
    NButton,
  },
  props: {
    bannerData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.banner-container {
  background: $stone;
  border-radius: 1rem;
  margin: 4rem 0;
}
.banner-content {
  padding-left: 4rem;
  padding-top: 5rem;
  .banner-title {
    font-size: 3.25rem;
    line-height: 105%;
  }
  @include media-breakpoint-down(md) {
    padding: 3.5rem;
    span {
      width: 90%;
    }
    .banner-title {
      width: 90%;
      font-size: 2.5rem;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 2rem;
    span {
      width: 90%;
    }
    .banner-title {
      width: 90%;
      font-size: 1.75rem;
    }
  }
}
.cta-button {
  width: 12rem; 
}
.banner-image {
  img {
    max-height: 100%;
    max-width: 100%;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      max-width: 35rem;
      max-height: 22.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
</style>